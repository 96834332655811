
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as index5b8olLtbkY7_456_KD2b_45GXo2_45X1fbMbkgxVDjVAQ12JgMeta } from "/codebuild/output/src1243667753/src/packages/nuxt/pages/index.vue?macro=true";
import { default as login8nxJZzV7WNyA9wtvoEJSOFhxVzsQY_z_45v3hGVB2oDb4Meta } from "/codebuild/output/src1243667753/src/packages/nuxt/pages/login.vue?macro=true";
import { default as verifyB7VFl4A3b9Y69aKmUaaOdL7nx8_45JihGyK9oZAV0oEsYMeta } from "/codebuild/output/src1243667753/src/packages/nuxt/pages/verify.vue?macro=true";
import { default as sitemapUrZvopUyOF6rvhxvt2sb6VC7JaLm4Bas6gc7IU66BEUMeta } from "/codebuild/output/src1243667753/src/packages/nuxt/pages/sitemap.vue?macro=true";
import { default as about_45uspSLgh_458EcIatchWGbKI4XdLPZroVnBr0rIAhIafhFCgMeta } from "/codebuild/output/src1243667753/src/packages/nuxt/pages/about-us.vue?macro=true";
import { default as registerg6mXsKw2O4CHClH_45so9Z5BQVh3OCc5AZY0EXl02fTzQMeta } from "/codebuild/output/src1243667753/src/packages/nuxt/pages/register.vue?macro=true";
import { default as complaintsIhyNiHDEBKfYKk3mMh6zNcet7Ubn_45pXjelA_45zdjhPokMeta } from "/codebuild/output/src1243667753/src/packages/nuxt/pages/complaints.vue?macro=true";
import { default as contact_45us2lBtqqaqs46288MfePXBD2VSppIgWqBJHqf8GNsZaUoMeta } from "/codebuild/output/src1243667753/src/packages/nuxt/pages/contact-us.vue?macro=true";
import { default as indexFe2RMjtweu_8Tf5fkqu1rlh8kftDAl8O6_45rO3DFpmCEMeta } from "/codebuild/output/src1243667753/src/packages/nuxt/pages/media/index.vue?macro=true";
import { default as index2_1SbvJ7NltgoUfokhVnsFLAz3SLEQQ63eA1yw1wcUIMeta } from "/codebuild/output/src1243667753/src/packages/nuxt/pages/energy/index.vue?macro=true";
import { default as _91slug_93Ds_454nzz_vumJzvSKzbGk7e1ppnkafvL0pDsl4v7k7T4Meta } from "/codebuild/output/src1243667753/src/packages/nuxt/pages/media/[slug].vue?macro=true";
import { default as partnerships3hmmTRkrmKc4hAX_452odJnvA1UPRHgTZOPXp1luXLRrwMeta } from "/codebuild/output/src1243667753/src/packages/nuxt/pages/partnerships.vue?macro=true";
import { default as terms_45of_45useUdxjiTOnY_M84K3_45FJWjtaEtWzneQPzmqGVDanbjZaAMeta } from "/codebuild/output/src1243667753/src/packages/nuxt/pages/terms-of-use.vue?macro=true";
import { default as indexnee86UJg9RxmtdkWzPBZgorp42OWkK4c1gMYLEiy7cQMeta } from "/codebuild/output/src1243667753/src/packages/nuxt/pages/careers/index.vue?macro=true";
import { default as privacy_45policyg4jVJ_45X535W7k4HnCqimfOu2tL8aJdZv62nbFtb8XzgMeta } from "/codebuild/output/src1243667753/src/packages/nuxt/pages/privacy-policy.vue?macro=true";
import { default as reset_45passwordvbA7oZqel7uGLZIiyLQmS43X6gdrqOHm56HRnlSUHb4Meta } from "/codebuild/output/src1243667753/src/packages/nuxt/pages/reset-password.vue?macro=true";
import { default as indexADxX_45_s0mD92LjM1b7yfLzHrqA8qOhWscxwUTg7ZxJ8Meta } from "/codebuild/output/src1243667753/src/packages/nuxt/pages/car-loans/index.vue?macro=true";
import { default as indexUBr3qLzUYehkwiztb0L_MRszLfoSKXVbenlOFa3eJZEMeta } from "/codebuild/output/src1243667753/src/packages/nuxt/pages/dashboard/index.vue?macro=true";
import { default as forgot_45passwordF3uFYYd02BOjjehaeL2Q_45IG9ewD_45VtQ_45SZ1RPMA9tLcMeta } from "/codebuild/output/src1243667753/src/packages/nuxt/pages/forgot-password.vue?macro=true";
import { default as errorNqeSSHPfwRVaGDqWBSUTD6wA8jLI4burYnCyfA6RDLsMeta } from "/codebuild/output/src1243667753/src/packages/nuxt/pages/energy/cdr/error.vue?macro=true";
import { default as indexY7NNwvSoVvPw6ukOCoViQwMpubQ8v2Hht_45fR5lRP6r4Meta } from "/codebuild/output/src1243667753/src/packages/nuxt/pages/home-loans/index.vue?macro=true";
import { default as _91slug_93nhsTOD_B6Oe6E7JZIyRDuMr2meirK9JFTdrgFoLhfvUMeta } from "/codebuild/output/src1243667753/src/packages/nuxt/pages/[vertical]/[slug].vue?macro=true";
import { default as guideshAKKUk6NnTsXJTj_456_45S6mgKdSQ3f9vfl1Oszzj5DN1AMeta } from "/codebuild/output/src1243667753/src/packages/nuxt/pages/[vertical]/guides.vue?macro=true";
import { default as collection_45noticeffzlxXiHfF0Uob0z2jItyRgrk7z1TYaySc0wUte3zXAMeta } from "/codebuild/output/src1243667753/src/packages/nuxt/pages/collection-notice.vue?macro=true";
import { default as indexZ_45RHesA6ruXxbP6kcbbll1bkjZATHTThM45TLqBixcgMeta } from "/codebuild/output/src1243667753/src/packages/nuxt/pages/credit-cards/index.vue?macro=true";
import { default as set_45passwordFlj2sIZPgpOzlsV5ktap0_45976KaoEKiJxCE41WwKZ2cMeta } from "/codebuild/output/src1243667753/src/packages/nuxt/pages/guest/set-password.vue?macro=true";
import { default as welcome_45backyNqy3snMKIRAokzqcewpGKmobuRkfZUJEaxpbo8w0kEMeta } from "/codebuild/output/src1243667753/src/packages/nuxt/pages/guest/welcome-back.vue?macro=true";
import { default as mergeUOSpN5Lj_DnD_7Hiv6iBD2JiADt0aKNIREMBsJFjV7oMeta } from "/codebuild/output/src1243667753/src/packages/nuxt/pages/authorization/merge.vue?macro=true";
import { default as indexa9Rus16AzGIgahHlDC3RpOwGxECKk6ATKc_45CX6zH7Y4Meta } from "/codebuild/output/src1243667753/src/packages/nuxt/pages/car-insurance/index.vue?macro=true";
import { default as _91slug_938uW3Z1K7dQy1tHRkgoDou9BhJrGmKRLzD093hiKMc00Meta } from "/codebuild/output/src1243667753/src/packages/nuxt/pages/credit-cards/[slug].vue?macro=true";
import { default as post_45sale_45thank_45youwawwaFywpiaO5CjEui_45nFU_8AHbxnyp1MdLC3DVoxy4Meta } from "/codebuild/output/src1243667753/src/packages/nuxt/pages/post-sale-thank-you.vue?macro=true";
import { default as thank_45youpeNaBvtjj3TxiyWezRfWCmExvJSxK1bsSQYAd_yWtq4Meta } from "/codebuild/output/src1243667753/src/packages/nuxt/pages/energy/cdr/thank-you.vue?macro=true";
import { default as indextjyBVdUbNxwfNM3ucIUn28ageBBIc_TnR9dIo8wHJEoMeta } from "/codebuild/output/src1243667753/src/packages/nuxt/pages/life-insurance/index.vue?macro=true";
import { default as indexd_ASjuVz44VbyuC33Lc9aIVa1FE0WTZAcqCZpwbqluEMeta } from "/codebuild/output/src1243667753/src/packages/nuxt/pages/personal-loans/index.vue?macro=true";
import { default as _91slug_93gYt2qr8nWonH20QTHVJ9H_45iuh2Ily5peuO_45W9aXWarkMeta } from "/codebuild/output/src1243667753/src/packages/nuxt/pages/preview/media/[slug].vue?macro=true";
import { default as indexu80Z2w_45wD0_45ji2lCON05WWL167MjDXtFD0g0KZ6HOLEMeta } from "/codebuild/output/src1243667753/src/packages/nuxt/pages/dashboard/perks/index.vue?macro=true";
import { default as indexAqj_vLJn5wMjQh12IMPKyBoKiR7o1njMa1F5AN5Lvy4Meta } from "/codebuild/output/src1243667753/src/packages/nuxt/pages/expert-analysis/index.vue?macro=true";
import { default as _91author_93nMmq_457On1K19DXV8dEaVjnGu3_EDmEyewMfFWWW6JNYMeta } from "/codebuild/output/src1243667753/src/packages/nuxt/pages/media/author/[author].vue?macro=true";
import { default as callbackW1H67yfppKg_45tNr3uZWI8zorw8YIIGoFNOKyO_451fQQMMeta } from "/codebuild/output/src1243667753/src/packages/nuxt/pages/authorization/callback.vue?macro=true";
import { default as buyer_45protection_45claimcN99MLq_45ZGocn3BCcsbpl_jvXT_UynpjXIApT8R7AOkMeta } from "/codebuild/output/src1243667753/src/packages/nuxt/pages/buyer-protection-claim.vue?macro=true";
import { default as indexOv6XNUeKCofQFEDqb3nxfShpCuDw4sHcWL0xYMTnQvYMeta } from "/codebuild/output/src1243667753/src/packages/nuxt/pages/dashboard/quotes/index.vue?macro=true";
import { default as thank_45youCLaB2wIvKAfC1uA6e9dm6frBAcI2YgBAbVwQenXvNdYMeta } from "/codebuild/output/src1243667753/src/packages/nuxt/pages/energy/quote/thank-you.vue?macro=true";
import { default as error0aIaoyqXuxDu4q19fvOTWgjMVS6P1NufXqkHlig2vaAMeta } from "/codebuild/output/src1243667753/src/packages/nuxt/pages/health-insurance/error.vue?macro=true";
import { default as indexoJ3KdcPFzM3diRsnUNPXc35eV06_pRbIdqSN051XPRoMeta } from "/codebuild/output/src1243667753/src/packages/nuxt/pages/health-insurance/index.vue?macro=true";
import { default as indexFOF_45G3G9Dl624by6dU4AZpSumhGPd9_H8Wfko0lut2gMeta } from "/codebuild/output/src1243667753/src/packages/nuxt/pages/dashboard/rewards/index.vue?macro=true";
import { default as errorV7xiJa6gNrV6FBcoKVskNycpP7AG19K2rZmfUPhXcY0Meta } from "/codebuild/output/src1243667753/src/packages/nuxt/pages/energy/application/error.vue?macro=true";
import { default as _91step_93dXGoJw5jpcmSH7QTskD1x9wcFuQxQ25WnW0M00sxtskMeta } from "/codebuild/output/src1243667753/src/packages/nuxt/pages/energy/quote/form/[step].vue?macro=true";
import { default as detailsRTW_45srI0R0Ivrp_45dPTdYp4KK1u7ebAHtwR6yfY8kDuYMeta } from "/codebuild/output/src1243667753/src/packages/nuxt/pages/energy/quote/form/details.vue?macro=true";
import { default as interest_45ratesY5LFbMyRUq1I6uwtq5_45lpIdKvIbrlxv96RKg3YIVaDAMeta } from "/codebuild/output/src1243667753/src/packages/nuxt/pages/home-loans/interest-rates.vue?macro=true";
import { default as _91slug_93jupfYL3IQ2S7spht6G8KFoZZGHKdPYPnXRPQ0K_45I4sgMeta } from "/codebuild/output/src1243667753/src/packages/nuxt/pages/preview/[vertical]/[slug].vue?macro=true";
import { default as indexbl0gqjwjWFXhd8iz2jDPczUuVYhokcmvYpxGt9LUq0kMeta } from "/codebuild/output/src1243667753/src/packages/nuxt/pages/[vertical]/companies/index.vue?macro=true";
import { default as account_45settingsoaREu51tz8kxSBQNedJJP2E4XvhcfOtQgVqJrm_45LsD0Meta } from "/codebuild/output/src1243667753/src/packages/nuxt/pages/dashboard/account-settings.vue?macro=true";
import { default as indexcIUXJJXeowEe_45MAX56fX4t7yqHUNcNGfIW5l9ZyMvoAMeta } from "/codebuild/output/src1243667753/src/packages/nuxt/pages/dashboard/onboarding/index.vue?macro=true";
import { default as live_45chat1B1MvSdUxyzF0seqCqcZtJb_45U1HOGJnDNZr7eoyjkSIMeta } from "/codebuild/output/src1243667753/src/packages/nuxt/pages/health-insurance/live-chat.vue?macro=true";
import { default as post_45saleiddXTbLOY4RQRO3E41kZR2XYH9GLBy8SbSO4aWJtWjsMeta } from "/codebuild/output/src1243667753/src/packages/nuxt/pages/health-insurance/post-sale.vue?macro=true";
import { default as thank_45youoI1cEU1yYMybBPw0Nb96_WVzsKWSPZjJIB7dN3yLNNMMeta } from "/codebuild/output/src1243667753/src/packages/nuxt/pages/home-loans/quote/thank-you.vue?macro=true";
import { default as comprehensivexA1_45NmXgaZwaJPIyEGbrWZAZhMNelWb6njrhI7jg2jAMeta } from "/codebuild/output/src1243667753/src/packages/nuxt/pages/car-insurance/comprehensive.vue?macro=true";
import { default as _91slug_93mBHuCaUG_450tYCQq7wVu_c0V4yzzsclQzSorwlTsfGssMeta } from "/codebuild/output/src1243667753/src/packages/nuxt/pages/credit-cards/preview/[slug].vue?macro=true";
import { default as add_d3Yv0mNlENFg5tRBjMRU3jmhpbcJBfRHy9jFJcbVOkMeta } from "/codebuild/output/src1243667753/src/packages/nuxt/pages/life-insurance/partners/add.vue?macro=true";
import { default as indexkqywElBTX1CBioQdk1S735SEsK_45_nzi3OnB0oSIorSYMeta } from "/codebuild/output/src1243667753/src/packages/nuxt/pages/[vertical]/schedule-callback/index.vue?macro=true";
import { default as _91response_93RzpUAibyIi8bbLJb3H92IxTanIEqT33OYm0yBKsHMg0Meta } from "/codebuild/output/src1243667753/src/packages/nuxt/pages/[vertical]/schedule-callback/[response].vue?macro=true";
import { default as schedule_45callbackH_45f_45kfstjifxiRmoWF5bshEBAotKCx8RwLK4gfqsC3cMeta } from "/codebuild/output/src1243667753/src/packages/nuxt/pages/[vertical]/schedule-callback.vue?macro=true";
import { default as _91slug_937VKgai5mWiLk_45GfIGkegjcHGLUFpyEhw6IdnIVoRtuwMeta } from "/codebuild/output/src1243667753/src/packages/nuxt/pages/credit-cards/redirect/[slug].vue?macro=true";
import { default as thank_45youx_45QgVvrKZlyJr5CHWr9DBg9jFo0bkJFUjMgdgG5Z_iwMeta } from "/codebuild/output/src1243667753/src/packages/nuxt/pages/energy/application/thank-you.vue?macro=true";
import { default as live_45chat_45b0uGRZanI_45T6ROhrHscIfAkr8WxQZAnmvz21mQrZwuRMMeta } from "/codebuild/output/src1243667753/src/packages/nuxt/pages/health-insurance/live-chat-b.vue?macro=true";
import { default as indexekzNQ2shY4eFqXcRHcJsvBWZqd0IvULV1x7zoIWen08Meta } from "/codebuild/output/src1243667753/src/packages/nuxt/pages/home-loans/calculators/index.vue?macro=true";
import { default as _91step_93QLfLA9aKqV4UIJKmQ9VCI7ysdfnN2luzrTLWKil2HDMMeta } from "/codebuild/output/src1243667753/src/packages/nuxt/pages/home-loans/quote/form/[step].vue?macro=true";
import { default as _91slug_93uUipJ_4548yYj3bXWwgI42iNvueVOGnHIvLB201_eueOcMeta } from "/codebuild/output/src1243667753/src/packages/nuxt/pages/car-insurance/redirect/[slug].vue?macro=true";
import { default as indexiPV0LwSPqZ7wnziiODttksV1Rog52EzMMqMewqVg2pIMeta } from "/codebuild/output/src1243667753/src/packages/nuxt/pages/health-insurance/offers/index.vue?macro=true";
import { default as addxNmWCCoRJgeDlLHxb_45dAt88yykuKfLLzpNv7D95c6QoMeta } from "/codebuild/output/src1243667753/src/packages/nuxt/pages/health-insurance/partners/add.vue?macro=true";
import { default as _91type_931WJegJkK3PA09N7zBy8GnlkmmyqCbY_HeylzHhdiSxoMeta } from "/codebuild/output/src1243667753/src/packages/nuxt/pages/home-loans/calculators/[type].vue?macro=true";
import { default as details2iHEoEUJtACXvPJDAjzFwNNR8ZjXuEupvfZ3J1hiBosMeta } from "/codebuild/output/src1243667753/src/packages/nuxt/pages/home-loans/quote/form/details.vue?macro=true";
import { default as callbackhZ94_45zSAHbigE5nEn20oFpUTIlMrqLkUSxbZ0NkJm6UMeta } from "/codebuild/output/src1243667753/src/packages/nuxt/pages/life-insurance/quote/callback.vue?macro=true";
import { default as digital_45eicK75DmahhWZKNPf8ts3qAjVzIQ1_MNsGRXfagitdgjm0Meta } from "/codebuild/output/src1243667753/src/packages/nuxt/pages/energy/application/digital-eic.vue?macro=true";
import { default as thank_45youel_455hSLVI9mOg6gf0JL6vDIUgSHynPjybx5fv2JjUL8Meta } from "/codebuild/output/src1243667753/src/packages/nuxt/pages/personal-loans/quote/thank-you.vue?macro=true";
import { default as _91step_93l9yEHlpnMkS8NFDRjuSO9CfUCfziNPmeKKO_45wpWtS9wMeta } from "/codebuild/output/src1243667753/src/packages/nuxt/pages/car-insurance/quote/form/[step].vue?macro=true";
import { default as _91author_93bNI8PmdWy2xIPE_c04XxqY5LcQIdlwBUW9TPuieo3NAMeta } from "/codebuild/output/src1243667753/src/packages/nuxt/pages/expert-analysis/author/[author].vue?macro=true";
import { default as comparisonOLimS50g_qzpmA5NmBkxPZU8_Ogue5OV2JzpK_45rPttQMeta } from "/codebuild/output/src1243667753/src/packages/nuxt/pages/life-insurance/quote/comparison.vue?macro=true";
import { default as step1damGwjRnRqlMiUPdHZ_jdv60V95Sq9Y9S8kUbHJd_45_gMeta } from "/codebuild/output/src1243667753/src/packages/nuxt/pages/life-insurance/quote/form/step1.vue?macro=true";
import { default as details40h8pvcjijZdXrQvUwWKFYTlmiJBT14gWV2BzlYNkqcMeta } from "/codebuild/output/src1243667753/src/packages/nuxt/pages/car-insurance/quote/form/details.vue?macro=true";
import { default as resultssRbEH4znbCyAlxTE1fmORhAD1IjbNeRsEs1OjIUShyEMeta } from "/codebuild/output/src1243667753/src/packages/nuxt/pages/car-insurance/quote/form/results.vue?macro=true";
import { default as indexqTBb7K7c_45ONRj5Emld_45prL0E3BLkWAcN_J33KTwmtm0Meta } from "/codebuild/output/src1243667753/src/packages/nuxt/pages/expert-analysis/[category]/index.vue?macro=true";
import { default as signupKapAPhDNykiDA3ZWliz12sYxwJAcOhqeYZiWt5hYQaEMeta } from "/codebuild/output/src1243667753/src/packages/nuxt/pages/health-insurance/partners/signup.vue?macro=true";
import { default as thank_45youiJt0TVCoRyIvomMgFM_45pTXqrdcatsxAH8EKIpMXRes4Meta } from "/codebuild/output/src1243667753/src/packages/nuxt/pages/health-insurance/quote/thank-you.vue?macro=true";
import { default as _91step_939mPJ_45NKk5yfl8wJf01iL_45GelGY0rOK5izLhWc2nvfvsMeta } from "/codebuild/output/src1243667753/src/packages/nuxt/pages/personal-loans/quote/form/[step].vue?macro=true";
import { default as _91slug_93h0yjN8ZSqRlsTkUPKBVGMOQ1qz_i2uH_45qZnT9Lp3RFAMeta } from "/codebuild/output/src1243667753/src/packages/nuxt/pages/expert-analysis/[category]/[slug].vue?macro=true";
import { default as step0rYh__zs_0dYQ77szIV8hvhUIKjRxFNLL8UIPifDtpH4Meta } from "/codebuild/output/src1243667753/src/packages/nuxt/pages/health-insurance/quote/form/step0.vue?macro=true";
import { default as existing_45customers86yz_dwTh_45JF0EowimOdOnOgKpOR_4ii_iyTmGR0Q3QMeta } from "/codebuild/output/src1243667753/src/packages/nuxt/pages/life-insurance/existing-customers.vue?macro=true";
import { default as thank_45yousV9_452R4YwRa6zwUcDq07Qvz1JTdZ9VV6_aVahGKFJacMeta } from "/codebuild/output/src1243667753/src/packages/nuxt/pages/life-insurance/partners/thank-you.vue?macro=true";
import { default as detailsjcnqtOV9rKu2fGlW6vZfPG67deKBjWVy_7t5YLde0w8Meta } from "/codebuild/output/src1243667753/src/packages/nuxt/pages/life-insurance/quote/form/details.vue?macro=true";
import { default as detailsp_45kVWQnUVZgGV4maJZtpGR0_45BuMb32EFxBpkWUfGWJAMeta } from "/codebuild/output/src1243667753/src/packages/nuxt/pages/personal-loans/quote/form/details.vue?macro=true";
import { default as _91step_93MvbgLc2F0UUXRogMXktz2671PEZZbQeLnEnVfFTZxHwMeta } from "/codebuild/output/src1243667753/src/packages/nuxt/pages/health-insurance/quote/form/[step].vue?macro=true";
import { default as rebatevH4xCskEf7_45K_tArvGLRLrNUQTj_Nnay9P80LodIxk0Meta } from "/codebuild/output/src1243667753/src/packages/nuxt/pages/dashboard/vault/[...product]/rebate.vue?macro=true";
import { default as _91step_93c1aWu2CBeXue56hTsh5HcpTfM8dvk_7O35Cb1bu5_c8Meta } from "/codebuild/output/src1243667753/src/packages/nuxt/pages/health-insurance/application/[step].vue?macro=true";
import { default as detailsIkKbDRxEyTOOysJsFgJOFRe47YdA4WTlYc8AB5kwM4wMeta } from "/codebuild/output/src1243667753/src/packages/nuxt/pages/health-insurance/quote/form/details.vue?macro=true";
import { default as step0G1naJRiawvEaAbpbYgy2_jdriXRkl_450U9MXnVQPkHK8Meta } from "/codebuild/output/src1243667753/src/packages/nuxt/pages/health-insurance/quote/policy/step0.vue?macro=true";
import { default as indexEn_zgw_45L_45krBtmof0mvEq6zCgLG3gm1pmU3eJqYnqfMMeta } from "/codebuild/output/src1243667753/src/packages/nuxt/pages/health-insurance/quote/resume/index.vue?macro=true";
import { default as indexJGg0yA7fUi7QcgjXExpo1XFEz3btxEkJCji2FkGZZLQMeta } from "/codebuild/output/src1243667753/src/packages/nuxt/pages/life-insurance/quote/customer/index.vue?macro=true";
import { default as detailsASyinr8IFNcBO3d2VHfM7OOuAfK3KVEvZ2dSLsIuQ4gMeta } from "/codebuild/output/src1243667753/src/packages/nuxt/pages/dashboard/vault/[...product]/details.vue?macro=true";
import { default as terms_45and_45conditionsW_45GZ6U55puKwAcC05MOTupekVSGtzD2BXvom0PE8Bs8Meta } from "/codebuild/output/src1243667753/src/packages/nuxt/pages/buyer-protection/terms-and-conditions.vue?macro=true";
import { default as _91step_93TQqIG3ZQ3PhUnwkOeezAIAHdz0wObOmmZLIJgZ3TWzUMeta } from "/codebuild/output/src1243667753/src/packages/nuxt/pages/health-insurance/applicationV2/[step].vue?macro=true";
import { default as indexsFUBWWvOvvr4gna0QsMn7JpiqTdfsnT4sCaYAQGAjOIMeta } from "/codebuild/output/src1243667753/src/packages/nuxt/pages/health-insurance/quote/customer/index.vue?macro=true";
import { default as detailsxZshNFgVfCyFQU718Tw32dK0w08yMMypyIXH2TLOVaoMeta } from "/codebuild/output/src1243667753/src/packages/nuxt/pages/health-insurance/quote/policy/details.vue?macro=true";
import { default as index9xT88XGucMVwNUFKXJMYY1_45O1dCZpm8e7DHTHFQH4Meta } from "/codebuild/output/src1243667753/src/packages/nuxt/pages/health-insurance/quotes-results/index.vue?macro=true";
import { default as successasjqV4xDYwJk7WOTXdpGdKPXjmB1i6LGi5RaojJW7cEMeta } from "/codebuild/output/src1243667753/src/packages/nuxt/pages/life-insurance/quote/customer/success.vue?macro=true";
import { default as claim_45offer2if5OcxMlB6gGCigo_45BKpAbxdUKdfT0c9ldkumM_459jQMeta } from "/codebuild/output/src1243667753/src/packages/nuxt/pages/dashboard/offers/[vertical]/claim-offer.vue?macro=true";
import { default as lic_45detailsXAbaiK6hgmqp4kCHNBYxncM0VHFMDbR5VgQfZUc8GoIMeta } from "/codebuild/output/src1243667753/src/packages/nuxt/pages/dashboard/vault/[...product]/lic-details.vue?macro=true";
import { default as coffs_45harbourdbF_TXpo2yPRQb6Nnolo0RdEX_5usnAG3MSMyAP4svMMeta } from "/codebuild/output/src1243667753/src/packages/nuxt/pages/energy/compare-by-location/coffs-harbour.vue?macro=true";
import { default as _91step_93lef9jnrMLOpbGeMNmWeXKXj5nZJXj4lmlghkY8b4lQkMeta } from "/codebuild/output/src1243667753/src/packages/nuxt/pages/health-insurance/quote/policy/step/[step].vue?macro=true";
import { default as index1r5wQiMaQRkl3c8WHM_J6KwqyWhJqFVSzq0IkAF8hmcMeta } from "/codebuild/output/src1243667753/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/index.vue?macro=true";
import { default as _91slug_934bhC_45n6zkDnIUXzwElwK0rFjK_riS3IB9kH5vxQAoZ0Meta } from "/codebuild/output/src1243667753/src/packages/nuxt/pages/preview/expert-analysis/[vertical]/[slug].vue?macro=true";
import { default as indexF_mNUCxX1rJI_45xVsaTDr6LrECCef4pvoSbTlZ9MS6LwMeta } from "/codebuild/output/src1243667753/src/packages/nuxt/pages/health-insurance/application/payment/index.vue?macro=true";
import { default as _91step_934kZOOqhDoJS21M82NaqmUVMFr_X_45YS5rh1K0523qBEoMeta } from "/codebuild/output/src1243667753/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/[step].vue?macro=true";
import { default as thank_45youN_45eTjq_zpvmsHuuj680oM5vOfKiVCdKwSJblAcqCjaIMeta } from "/codebuild/output/src1243667753/src/packages/nuxt/pages/life-insurance/quote/application/thank-you.vue?macro=true";
import { default as indexRpZfD4cwRsfLGuhWsZ9uOGJ_45EA_0YJaXoXbeP3vFWxcMeta } from "/codebuild/output/src1243667753/src/packages/nuxt/pages/health-insurance/application/thankyou/index.vue?macro=true";
import { default as indexOWO3LWqOK9L50O8_R3VNdPJVhUXXZjIVRwaZXtewIpIMeta } from "/codebuild/output/src1243667753/src/packages/nuxt/pages/health-insurance/rate-rise-calculator/index.vue?macro=true";
import { default as detailsOa5kX6wSiXdcVZtaur7BXt7Fblyhblg36sCry3VcPa4Meta } from "/codebuild/output/src1243667753/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/details.vue?macro=true";
import { default as expiredr1Vh6M1FOWXix3hqdjdbF44fO67_453tabuRlq2m2IzuwMeta } from "/codebuild/output/src1243667753/src/packages/nuxt/pages/health-insurance/application/payment/expired.vue?macro=true";
import { default as index9S62UxWJmF4891oni9RlvXT5qn5lNJNETyseN_WI4nwMeta } from "/codebuild/output/src1243667753/src/packages/nuxt/pages/life-insurance/income-protection/quote/index.vue?macro=true";
import { default as thank_45youeYAQwsGZAUCLEMYWReFOR_45OgswiqCo9XRrzQaPgJ7KAMeta } from "/codebuild/output/src1243667753/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/thank-you.vue?macro=true";
import { default as detailssf9gAF0gvFhwxGiM0_c4c_9kFOc1vUIWIUi_45I2UFrX4Meta } from "/codebuild/output/src1243667753/src/packages/nuxt/pages/life-insurance/income-protection/quote/details.vue?macro=true";
import { default as pre_45assesment9M14wZFypaQgmldsJ9GAJKWbz0YKPYpDuH2Lx_M9fHsMeta } from "/codebuild/output/src1243667753/src/packages/nuxt/pages/life-insurance/quote/application/pre-assesment.vue?macro=true";
import { default as confirmationJZhOa95ROflW0QHiITkiK_452bjWuSchpz06kYvReCFDoMeta } from "/codebuild/output/src1243667753/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/confirmation.vue?macro=true";
import { default as completionrggE_dsKzKDBnF9Kc1jmHLZEoBj7wQE2CmcwOEIqP9UMeta } from "/codebuild/output/src1243667753/src/packages/nuxt/pages/dashboard/vault/[...product]/[vertical]/completion.vue?macro=true";
import { default as quotes_45resultsv9lgQmwIRwqJuWNF7GmYncWvkHF3X90_45CNm4RruWcR0Meta } from "/codebuild/output/src1243667753/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/quotes-results.vue?macro=true";
import { default as confirmation54ntIpSqz2MMlOXeYkjWrV16rsg33odzLtyX5BftMFoMeta } from "/codebuild/output/src1243667753/src/packages/nuxt/pages/life-insurance/income-protection/quote/confirmation.vue?macro=true";
import { default as _91step_93DctHuIr3ygIReMmBWLRY8yqgRt96Ugho8c7iK8md_UYMeta } from "/codebuild/output/src1243667753/src/packages/nuxt/pages/dashboard/vault/[...product]/form/energy/step/[step].vue?macro=true";
import { default as _91step_93O6UNR4uKJZ4bKZ_CieSg1AsIbcijxSidhMIJVxGYKvcMeta } from "/codebuild/output/src1243667753/src/packages/nuxt/pages/dashboard/vault/[...product]/form/generic/step/[step].vue?macro=true";
import { default as _91step_93eio1iBmsUMDJrDcaTGXt5vpvZ4mNmzJTK2Gm0tYvbNIMeta } from "/codebuild/output/src1243667753/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/application/[step].vue?macro=true";
import { default as _91step_935J3WPp_45VqdasgonMQp2pSlcDZMXZX4ESZy4joEOxmr8Meta } from "/codebuild/output/src1243667753/src/packages/nuxt/pages/dashboard/vault/[...product]/form/home-loan/step/[step].vue?macro=true";
import { default as indexTvCyv6sUOJVeNMrBMKUEo7zvfbKgDnsSaIf_45Wz7yTgAMeta } from "/codebuild/output/src1243667753/src/packages/nuxt/pages/health-insurance/students-offer/[university]/form/index.vue?macro=true";
import { default as additional_45detailsF6g585y1p6QTZS9x8SdSfa4ks4NiA96y5z_455m0NAVtEMeta } from "/codebuild/output/src1243667753/src/packages/nuxt/pages/dashboard/vault/[...product]/[vertical]/additional-details.vue?macro=true";
import { default as _91step_93Q7LZYj_N0SQP6CjfsyWBEQRP6kXc_VrE1vXqtsQNO6EMeta } from "/codebuild/output/src1243667753/src/packages/nuxt/pages/dashboard/vault/[...product]/form/life-insurance/step/[step].vue?macro=true";
import { default as _91step_93RFaz0p7VcQ7l8jvQ2ej4ZoIl3Q6lhLV25oo_QtGMHpkMeta } from "/codebuild/output/src1243667753/src/packages/nuxt/pages/dashboard/vault/[...product]/form/health-insurance/step/[step].vue?macro=true";
import { default as indexGuVbNWxMiMe9QxVZLnoMkyoRFHXHFHl3T0IOBlCEmhkMeta } from "/codebuild/output/src1243667753/src/packages/nuxt/pages/health-insurance/students-offer/[university]/terms-conditions/index.vue?macro=true";
import { default as indexhRAgD4axfY1PEF4xdcKu2We_aGBm9ofDCXWdDQEE5RoMeta } from "/codebuild/output/src1243667753/src/packages/nuxt/pages/health-insurance/budget-hub/health-insurance-average-cost-calculator/index.vue?macro=true";
export default [
  {
    name: "index",
    path: "/",
    component: () => import("/codebuild/output/src1243667753/src/packages/nuxt/pages/index.vue")
  },
  {
    name: "login",
    path: "/login",
    meta: login8nxJZzV7WNyA9wtvoEJSOFhxVzsQY_z_45v3hGVB2oDb4Meta || {},
    component: () => import("/codebuild/output/src1243667753/src/packages/nuxt/pages/login.vue")
  },
  {
    name: "verify",
    path: "/verify",
    component: () => import("/codebuild/output/src1243667753/src/packages/nuxt/pages/verify.vue")
  },
  {
    name: "sitemap",
    path: "/sitemap",
    component: () => import("/codebuild/output/src1243667753/src/packages/nuxt/pages/sitemap.vue")
  },
  {
    name: "about-us",
    path: "/about-us",
    component: () => import("/codebuild/output/src1243667753/src/packages/nuxt/pages/about-us.vue")
  },
  {
    name: "register",
    path: "/register",
    meta: registerg6mXsKw2O4CHClH_45so9Z5BQVh3OCc5AZY0EXl02fTzQMeta || {},
    component: () => import("/codebuild/output/src1243667753/src/packages/nuxt/pages/register.vue")
  },
  {
    name: "complaints",
    path: "/complaints",
    component: () => import("/codebuild/output/src1243667753/src/packages/nuxt/pages/complaints.vue")
  },
  {
    name: "contact-us",
    path: "/contact-us",
    component: () => import("/codebuild/output/src1243667753/src/packages/nuxt/pages/contact-us.vue")
  },
  {
    name: "media",
    path: "/media",
    component: () => import("/codebuild/output/src1243667753/src/packages/nuxt/pages/media/index.vue")
  },
  {
    name: "energy",
    path: "/energy",
    component: () => import("/codebuild/output/src1243667753/src/packages/nuxt/pages/energy/index.vue")
  },
  {
    name: "media-slug",
    path: "/media/:slug()",
    component: () => import("/codebuild/output/src1243667753/src/packages/nuxt/pages/media/[slug].vue")
  },
  {
    name: "partnerships",
    path: "/partnerships",
    component: () => import("/codebuild/output/src1243667753/src/packages/nuxt/pages/partnerships.vue")
  },
  {
    name: "terms-of-use",
    path: "/terms-of-use",
    component: () => import("/codebuild/output/src1243667753/src/packages/nuxt/pages/terms-of-use.vue")
  },
  {
    name: "careers",
    path: "/careers",
    component: () => import("/codebuild/output/src1243667753/src/packages/nuxt/pages/careers/index.vue")
  },
  {
    name: "privacy-policy",
    path: "/privacy-policy",
    component: () => import("/codebuild/output/src1243667753/src/packages/nuxt/pages/privacy-policy.vue")
  },
  {
    name: "reset-password",
    path: "/reset-password",
    meta: reset_45passwordvbA7oZqel7uGLZIiyLQmS43X6gdrqOHm56HRnlSUHb4Meta || {},
    component: () => import("/codebuild/output/src1243667753/src/packages/nuxt/pages/reset-password.vue")
  },
  {
    name: "car-loans",
    path: "/car-loans",
    component: () => import("/codebuild/output/src1243667753/src/packages/nuxt/pages/car-loans/index.vue")
  },
  {
    name: "dashboard",
    path: "/dashboard",
    meta: indexUBr3qLzUYehkwiztb0L_MRszLfoSKXVbenlOFa3eJZEMeta || {},
    component: () => import("/codebuild/output/src1243667753/src/packages/nuxt/pages/dashboard/index.vue")
  },
  {
    name: "forgot-password",
    path: "/forgot-password",
    meta: forgot_45passwordF3uFYYd02BOjjehaeL2Q_45IG9ewD_45VtQ_45SZ1RPMA9tLcMeta || {},
    component: () => import("/codebuild/output/src1243667753/src/packages/nuxt/pages/forgot-password.vue")
  },
  {
    name: "energy-cdr-error",
    path: "/energy/cdr/error",
    component: () => import("/codebuild/output/src1243667753/src/packages/nuxt/pages/energy/cdr/error.vue")
  },
  {
    name: "home-loans",
    path: "/home-loans",
    component: () => import("/codebuild/output/src1243667753/src/packages/nuxt/pages/home-loans/index.vue")
  },
  {
    name: "vertical-slug",
    path: "/:vertical()/:slug()",
    component: () => import("/codebuild/output/src1243667753/src/packages/nuxt/pages/[vertical]/[slug].vue")
  },
  {
    name: "vertical-guides",
    path: "/:vertical()/guides",
    component: () => import("/codebuild/output/src1243667753/src/packages/nuxt/pages/[vertical]/guides.vue")
  },
  {
    name: "collection-notice",
    path: "/collection-notice",
    component: () => import("/codebuild/output/src1243667753/src/packages/nuxt/pages/collection-notice.vue")
  },
  {
    name: "credit-cards",
    path: "/credit-cards",
    component: () => import("/codebuild/output/src1243667753/src/packages/nuxt/pages/credit-cards/index.vue")
  },
  {
    name: "guest-set-password",
    path: "/guest/set-password",
    meta: set_45passwordFlj2sIZPgpOzlsV5ktap0_45976KaoEKiJxCE41WwKZ2cMeta || {},
    component: () => import("/codebuild/output/src1243667753/src/packages/nuxt/pages/guest/set-password.vue")
  },
  {
    name: "guest-welcome-back",
    path: "/guest/welcome-back",
    component: () => import("/codebuild/output/src1243667753/src/packages/nuxt/pages/guest/welcome-back.vue")
  },
  {
    name: "authorization-merge",
    path: "/authorization/merge",
    component: () => import("/codebuild/output/src1243667753/src/packages/nuxt/pages/authorization/merge.vue")
  },
  {
    name: "car-insurance",
    path: "/car-insurance",
    component: () => import("/codebuild/output/src1243667753/src/packages/nuxt/pages/car-insurance/index.vue")
  },
  {
    name: "credit-cards-slug",
    path: "/credit-cards/:slug()",
    meta: _91slug_938uW3Z1K7dQy1tHRkgoDou9BhJrGmKRLzD093hiKMc00Meta || {},
    component: () => import("/codebuild/output/src1243667753/src/packages/nuxt/pages/credit-cards/[slug].vue")
  },
  {
    name: "post-sale-thank-you",
    path: "/post-sale-thank-you",
    component: () => import("/codebuild/output/src1243667753/src/packages/nuxt/pages/post-sale-thank-you.vue")
  },
  {
    name: "energy-cdr-thank-you",
    path: "/energy/cdr/thank-you",
    component: () => import("/codebuild/output/src1243667753/src/packages/nuxt/pages/energy/cdr/thank-you.vue")
  },
  {
    name: "life-insurance",
    path: "/life-insurance",
    component: () => import("/codebuild/output/src1243667753/src/packages/nuxt/pages/life-insurance/index.vue")
  },
  {
    name: "personal-loans",
    path: "/personal-loans",
    component: () => import("/codebuild/output/src1243667753/src/packages/nuxt/pages/personal-loans/index.vue")
  },
  {
    name: "preview-media-slug",
    path: "/preview/media/:slug()",
    component: () => import("/codebuild/output/src1243667753/src/packages/nuxt/pages/preview/media/[slug].vue")
  },
  {
    name: "dashboard-perks",
    path: "/dashboard/perks",
    meta: indexu80Z2w_45wD0_45ji2lCON05WWL167MjDXtFD0g0KZ6HOLEMeta || {},
    component: () => import("/codebuild/output/src1243667753/src/packages/nuxt/pages/dashboard/perks/index.vue")
  },
  {
    name: "expert-analysis",
    path: "/expert-analysis",
    component: () => import("/codebuild/output/src1243667753/src/packages/nuxt/pages/expert-analysis/index.vue")
  },
  {
    name: "media-author-author",
    path: "/media/author/:author()",
    component: () => import("/codebuild/output/src1243667753/src/packages/nuxt/pages/media/author/[author].vue")
  },
  {
    name: "authorization-callback",
    path: "/authorization/callback",
    component: () => import("/codebuild/output/src1243667753/src/packages/nuxt/pages/authorization/callback.vue")
  },
  {
    name: "buyer-protection-claim",
    path: "/buyer-protection-claim",
    component: () => import("/codebuild/output/src1243667753/src/packages/nuxt/pages/buyer-protection-claim.vue")
  },
  {
    name: "dashboard-quotes",
    path: "/dashboard/quotes",
    meta: indexOv6XNUeKCofQFEDqb3nxfShpCuDw4sHcWL0xYMTnQvYMeta || {},
    component: () => import("/codebuild/output/src1243667753/src/packages/nuxt/pages/dashboard/quotes/index.vue")
  },
  {
    name: "energy-quote-thank-you",
    path: "/energy/quote/thank-you",
    component: () => import("/codebuild/output/src1243667753/src/packages/nuxt/pages/energy/quote/thank-you.vue")
  },
  {
    name: "health-insurance-error",
    path: "/health-insurance/error",
    component: () => import("/codebuild/output/src1243667753/src/packages/nuxt/pages/health-insurance/error.vue")
  },
  {
    name: "health-insurance",
    path: "/health-insurance",
    component: () => import("/codebuild/output/src1243667753/src/packages/nuxt/pages/health-insurance/index.vue")
  },
  {
    name: "dashboard-rewards",
    path: "/dashboard/rewards",
    props: indexFOF_45G3G9Dl624by6dU4AZpSumhGPd9_H8Wfko0lut2gMeta?.props ?? {"verificationNeeded":true},
    meta: indexFOF_45G3G9Dl624by6dU4AZpSumhGPd9_H8Wfko0lut2gMeta || {},
    component: () => import("/codebuild/output/src1243667753/src/packages/nuxt/pages/dashboard/rewards/index.vue")
  },
  {
    name: "energy-application-error",
    path: "/energy/application/error",
    component: () => import("/codebuild/output/src1243667753/src/packages/nuxt/pages/energy/application/error.vue")
  },
  {
    name: "energy-quote-form-step",
    path: "/energy/quote/form/:step()",
    component: () => import("/codebuild/output/src1243667753/src/packages/nuxt/pages/energy/quote/form/[step].vue")
  },
  {
    name: "energy-quote-form-details",
    path: "/energy/quote/form/details",
    component: () => import("/codebuild/output/src1243667753/src/packages/nuxt/pages/energy/quote/form/details.vue")
  },
  {
    name: "home-loans-interest-rates",
    path: "/home-loans/interest-rates",
    component: () => import("/codebuild/output/src1243667753/src/packages/nuxt/pages/home-loans/interest-rates.vue")
  },
  {
    name: "preview-vertical-slug",
    path: "/preview/:vertical()/:slug()",
    component: () => import("/codebuild/output/src1243667753/src/packages/nuxt/pages/preview/[vertical]/[slug].vue")
  },
  {
    name: "vertical-companies",
    path: "/:vertical()/companies",
    component: () => import("/codebuild/output/src1243667753/src/packages/nuxt/pages/[vertical]/companies/index.vue")
  },
  {
    name: "dashboard-account-settings",
    path: "/dashboard/account-settings",
    meta: account_45settingsoaREu51tz8kxSBQNedJJP2E4XvhcfOtQgVqJrm_45LsD0Meta || {},
    component: () => import("/codebuild/output/src1243667753/src/packages/nuxt/pages/dashboard/account-settings.vue")
  },
  {
    name: "dashboard-onboarding",
    path: "/dashboard/onboarding",
    meta: indexcIUXJJXeowEe_45MAX56fX4t7yqHUNcNGfIW5l9ZyMvoAMeta || {},
    component: () => import("/codebuild/output/src1243667753/src/packages/nuxt/pages/dashboard/onboarding/index.vue")
  },
  {
    name: "health-insurance-live-chat",
    path: "/health-insurance/live-chat",
    component: () => import("/codebuild/output/src1243667753/src/packages/nuxt/pages/health-insurance/live-chat.vue")
  },
  {
    name: "health-insurance-post-sale",
    path: "/health-insurance/post-sale",
    component: () => import("/codebuild/output/src1243667753/src/packages/nuxt/pages/health-insurance/post-sale.vue")
  },
  {
    name: "home-loans-quote-thank-you",
    path: "/home-loans/quote/thank-you",
    component: () => import("/codebuild/output/src1243667753/src/packages/nuxt/pages/home-loans/quote/thank-you.vue")
  },
  {
    name: "car-insurance-comprehensive",
    path: "/car-insurance/comprehensive",
    component: () => import("/codebuild/output/src1243667753/src/packages/nuxt/pages/car-insurance/comprehensive.vue")
  },
  {
    name: "credit-cards-preview-slug",
    path: "/credit-cards/preview/:slug()",
    component: () => import("/codebuild/output/src1243667753/src/packages/nuxt/pages/credit-cards/preview/[slug].vue")
  },
  {
    name: "life-insurance-partners-add",
    path: "/life-insurance/partners/add",
    component: () => import("/codebuild/output/src1243667753/src/packages/nuxt/pages/life-insurance/partners/add.vue")
  },
  {
    name: schedule_45callbackH_45f_45kfstjifxiRmoWF5bshEBAotKCx8RwLK4gfqsC3cMeta?.name,
    path: "/:vertical()/schedule-callback",
    component: () => import("/codebuild/output/src1243667753/src/packages/nuxt/pages/[vertical]/schedule-callback.vue"),
    children: [
  {
    name: "vertical-schedule-callback",
    path: "",
    component: () => import("/codebuild/output/src1243667753/src/packages/nuxt/pages/[vertical]/schedule-callback/index.vue")
  },
  {
    name: "vertical-schedule-callback-response",
    path: ":response()",
    component: () => import("/codebuild/output/src1243667753/src/packages/nuxt/pages/[vertical]/schedule-callback/[response].vue")
  }
]
  },
  {
    name: "credit-cards-redirect-slug",
    path: "/credit-cards/redirect/:slug()",
    component: () => import("/codebuild/output/src1243667753/src/packages/nuxt/pages/credit-cards/redirect/[slug].vue")
  },
  {
    name: "energy-application-thank-you",
    path: "/energy/application/thank-you",
    component: () => import("/codebuild/output/src1243667753/src/packages/nuxt/pages/energy/application/thank-you.vue")
  },
  {
    name: "health-insurance-live-chat-b",
    path: "/health-insurance/live-chat-b",
    component: () => import("/codebuild/output/src1243667753/src/packages/nuxt/pages/health-insurance/live-chat-b.vue")
  },
  {
    name: "home-loans-calculators",
    path: "/home-loans/calculators",
    component: () => import("/codebuild/output/src1243667753/src/packages/nuxt/pages/home-loans/calculators/index.vue")
  },
  {
    name: "home-loans-quote-form-step",
    path: "/home-loans/quote/form/:step()",
    component: () => import("/codebuild/output/src1243667753/src/packages/nuxt/pages/home-loans/quote/form/[step].vue")
  },
  {
    name: "car-insurance-redirect-slug",
    path: "/car-insurance/redirect/:slug()",
    component: () => import("/codebuild/output/src1243667753/src/packages/nuxt/pages/car-insurance/redirect/[slug].vue")
  },
  {
    name: "health-insurance-offers",
    path: "/health-insurance/offers",
    component: () => import("/codebuild/output/src1243667753/src/packages/nuxt/pages/health-insurance/offers/index.vue")
  },
  {
    name: "health-insurance-partners-add",
    path: "/health-insurance/partners/add",
    component: () => import("/codebuild/output/src1243667753/src/packages/nuxt/pages/health-insurance/partners/add.vue")
  },
  {
    name: "home-loans-calculators-type",
    path: "/home-loans/calculators/:type()",
    component: () => import("/codebuild/output/src1243667753/src/packages/nuxt/pages/home-loans/calculators/[type].vue")
  },
  {
    name: "home-loans-quote-form-details",
    path: "/home-loans/quote/form/details",
    component: () => import("/codebuild/output/src1243667753/src/packages/nuxt/pages/home-loans/quote/form/details.vue")
  },
  {
    name: "life-insurance-quote-callback",
    path: "/life-insurance/quote/callback",
    component: () => import("/codebuild/output/src1243667753/src/packages/nuxt/pages/life-insurance/quote/callback.vue")
  },
  {
    name: "energy-application-digital-eic",
    path: "/energy/application/digital-eic",
    component: () => import("/codebuild/output/src1243667753/src/packages/nuxt/pages/energy/application/digital-eic.vue")
  },
  {
    name: "personal-loans-quote-thank-you",
    path: "/personal-loans/quote/thank-you",
    component: () => import("/codebuild/output/src1243667753/src/packages/nuxt/pages/personal-loans/quote/thank-you.vue")
  },
  {
    name: "car-insurance-quote-form-step",
    path: "/car-insurance/quote/form/:step()",
    component: () => import("/codebuild/output/src1243667753/src/packages/nuxt/pages/car-insurance/quote/form/[step].vue")
  },
  {
    name: "expert-analysis-author-author",
    path: "/expert-analysis/author/:author()",
    component: () => import("/codebuild/output/src1243667753/src/packages/nuxt/pages/expert-analysis/author/[author].vue")
  },
  {
    name: "life-insurance-quote-comparison",
    path: "/life-insurance/quote/comparison",
    component: () => import("/codebuild/output/src1243667753/src/packages/nuxt/pages/life-insurance/quote/comparison.vue")
  },
  {
    name: "life-insurance-quote-form-step1",
    path: "/life-insurance/quote/form/step1",
    component: () => import("/codebuild/output/src1243667753/src/packages/nuxt/pages/life-insurance/quote/form/step1.vue")
  },
  {
    name: "car-insurance-quote-form-details",
    path: "/car-insurance/quote/form/details",
    component: () => import("/codebuild/output/src1243667753/src/packages/nuxt/pages/car-insurance/quote/form/details.vue")
  },
  {
    name: "car-insurance-quote-form-results",
    path: "/car-insurance/quote/form/results",
    component: () => import("/codebuild/output/src1243667753/src/packages/nuxt/pages/car-insurance/quote/form/results.vue")
  },
  {
    name: "expert-analysis-category",
    path: "/expert-analysis/:category()",
    component: () => import("/codebuild/output/src1243667753/src/packages/nuxt/pages/expert-analysis/[category]/index.vue")
  },
  {
    name: "health-insurance-partners-signup",
    path: "/health-insurance/partners/signup",
    component: () => import("/codebuild/output/src1243667753/src/packages/nuxt/pages/health-insurance/partners/signup.vue")
  },
  {
    name: "health-insurance-quote-thank-you",
    path: "/health-insurance/quote/thank-you",
    component: () => import("/codebuild/output/src1243667753/src/packages/nuxt/pages/health-insurance/quote/thank-you.vue")
  },
  {
    name: "personal-loans-quote-form-step",
    path: "/personal-loans/quote/form/:step()",
    component: () => import("/codebuild/output/src1243667753/src/packages/nuxt/pages/personal-loans/quote/form/[step].vue")
  },
  {
    name: "expert-analysis-category-slug",
    path: "/expert-analysis/:category()/:slug()",
    component: () => import("/codebuild/output/src1243667753/src/packages/nuxt/pages/expert-analysis/[category]/[slug].vue")
  },
  {
    name: "health-insurance-quote-form-step0",
    path: "/health-insurance/quote/form/step0",
    component: () => import("/codebuild/output/src1243667753/src/packages/nuxt/pages/health-insurance/quote/form/step0.vue")
  },
  {
    name: "life-insurance-existing-customers",
    path: "/life-insurance/existing-customers",
    component: () => import("/codebuild/output/src1243667753/src/packages/nuxt/pages/life-insurance/existing-customers.vue")
  },
  {
    name: "life-insurance-partners-thank-you",
    path: "/life-insurance/partners/thank-you",
    component: () => import("/codebuild/output/src1243667753/src/packages/nuxt/pages/life-insurance/partners/thank-you.vue")
  },
  {
    name: "life-insurance-quote-form-details",
    path: "/life-insurance/quote/form/details",
    component: () => import("/codebuild/output/src1243667753/src/packages/nuxt/pages/life-insurance/quote/form/details.vue")
  },
  {
    name: "personal-loans-quote-form-details",
    path: "/personal-loans/quote/form/details",
    component: () => import("/codebuild/output/src1243667753/src/packages/nuxt/pages/personal-loans/quote/form/details.vue")
  },
  {
    name: "health-insurance-quote-form-step",
    path: "/health-insurance/quote/form/:step()",
    component: () => import("/codebuild/output/src1243667753/src/packages/nuxt/pages/health-insurance/quote/form/[step].vue")
  },
  {
    name: "dashboard-vault-product-rebate",
    path: "/dashboard/vault/:product([^/]*)*/rebate",
    meta: rebatevH4xCskEf7_45K_tArvGLRLrNUQTj_Nnay9P80LodIxk0Meta || {},
    component: () => import("/codebuild/output/src1243667753/src/packages/nuxt/pages/dashboard/vault/[...product]/rebate.vue")
  },
  {
    name: "health-insurance-application-step",
    path: "/health-insurance/application/:step()",
    component: () => import("/codebuild/output/src1243667753/src/packages/nuxt/pages/health-insurance/application/[step].vue")
  },
  {
    name: "health-insurance-quote-form-details",
    path: "/health-insurance/quote/form/details",
    component: () => import("/codebuild/output/src1243667753/src/packages/nuxt/pages/health-insurance/quote/form/details.vue")
  },
  {
    name: "health-insurance-quote-policy-step0",
    path: "/health-insurance/quote/policy/step0",
    component: () => import("/codebuild/output/src1243667753/src/packages/nuxt/pages/health-insurance/quote/policy/step0.vue")
  },
  {
    name: "health-insurance-quote-resume",
    path: "/health-insurance/quote/resume",
    component: () => import("/codebuild/output/src1243667753/src/packages/nuxt/pages/health-insurance/quote/resume/index.vue")
  },
  {
    name: "life-insurance-quote-customer",
    path: "/life-insurance/quote/customer",
    component: () => import("/codebuild/output/src1243667753/src/packages/nuxt/pages/life-insurance/quote/customer/index.vue")
  },
  {
    name: "dashboard-vault-product-details",
    path: "/dashboard/vault/:product([^/]*)*/details",
    meta: detailsASyinr8IFNcBO3d2VHfM7OOuAfK3KVEvZ2dSLsIuQ4gMeta || {},
    component: () => import("/codebuild/output/src1243667753/src/packages/nuxt/pages/dashboard/vault/[...product]/details.vue")
  },
  {
    name: "buyer-protection-terms-and-conditions",
    path: "/buyer-protection/terms-and-conditions",
    component: () => import("/codebuild/output/src1243667753/src/packages/nuxt/pages/buyer-protection/terms-and-conditions.vue")
  },
  {
    name: "health-insurance-applicationV2-step",
    path: "/health-insurance/applicationV2/:step()",
    component: () => import("/codebuild/output/src1243667753/src/packages/nuxt/pages/health-insurance/applicationV2/[step].vue")
  },
  {
    name: "health-insurance-quote-customer",
    path: "/health-insurance/quote/customer",
    component: () => import("/codebuild/output/src1243667753/src/packages/nuxt/pages/health-insurance/quote/customer/index.vue")
  },
  {
    name: "health-insurance-quote-policy-details",
    path: "/health-insurance/quote/policy/details",
    component: () => import("/codebuild/output/src1243667753/src/packages/nuxt/pages/health-insurance/quote/policy/details.vue")
  },
  {
    name: "health-insurance-quotes-results",
    path: "/health-insurance/quotes-results",
    component: () => import("/codebuild/output/src1243667753/src/packages/nuxt/pages/health-insurance/quotes-results/index.vue")
  },
  {
    name: "life-insurance-quote-customer-success",
    path: "/life-insurance/quote/customer/success",
    component: () => import("/codebuild/output/src1243667753/src/packages/nuxt/pages/life-insurance/quote/customer/success.vue")
  },
  {
    name: "dashboard-offers-vertical-claim-offer",
    path: "/dashboard/offers/:vertical()/claim-offer",
    meta: claim_45offer2if5OcxMlB6gGCigo_45BKpAbxdUKdfT0c9ldkumM_459jQMeta || {},
    component: () => import("/codebuild/output/src1243667753/src/packages/nuxt/pages/dashboard/offers/[vertical]/claim-offer.vue")
  },
  {
    name: "dashboard-vault-product-lic-details",
    path: "/dashboard/vault/:product([^/]*)*/lic-details",
    meta: lic_45detailsXAbaiK6hgmqp4kCHNBYxncM0VHFMDbR5VgQfZUc8GoIMeta || {},
    component: () => import("/codebuild/output/src1243667753/src/packages/nuxt/pages/dashboard/vault/[...product]/lic-details.vue")
  },
  {
    name: "energy-compare-by-location-coffs-harbour",
    path: "/energy/compare-by-location/coffs-harbour",
    component: () => import("/codebuild/output/src1243667753/src/packages/nuxt/pages/energy/compare-by-location/coffs-harbour.vue")
  },
  {
    name: "health-insurance-quote-policy-step-step",
    path: "/health-insurance/quote/policy/step/:step()",
    meta: _91step_93lef9jnrMLOpbGeMNmWeXKXj5nZJXj4lmlghkY8b4lQkMeta || {},
    component: () => import("/codebuild/output/src1243667753/src/packages/nuxt/pages/health-insurance/quote/policy/step/[step].vue")
  },
  {
    name: "health-insurance-visitors-visa-ovhc",
    path: "/health-insurance/visitors-visa-ovhc",
    component: () => import("/codebuild/output/src1243667753/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/index.vue")
  },
  {
    name: "preview-expert-analysis-vertical-slug",
    path: "/preview/expert-analysis/:vertical()/:slug()",
    component: () => import("/codebuild/output/src1243667753/src/packages/nuxt/pages/preview/expert-analysis/[vertical]/[slug].vue")
  },
  {
    name: "health-insurance-application-payment",
    path: "/health-insurance/application/payment",
    component: () => import("/codebuild/output/src1243667753/src/packages/nuxt/pages/health-insurance/application/payment/index.vue")
  },
  {
    name: "health-insurance-visitors-visa-ovhc-step",
    path: "/health-insurance/visitors-visa-ovhc/:step()",
    component: () => import("/codebuild/output/src1243667753/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/[step].vue")
  },
  {
    name: "life-insurance-quote-application-thank-you",
    path: "/life-insurance/quote/application/thank-you",
    component: () => import("/codebuild/output/src1243667753/src/packages/nuxt/pages/life-insurance/quote/application/thank-you.vue")
  },
  {
    name: "health-insurance-application-thankyou",
    path: "/health-insurance/application/thankyou",
    component: () => import("/codebuild/output/src1243667753/src/packages/nuxt/pages/health-insurance/application/thankyou/index.vue")
  },
  {
    name: "health-insurance-rate-rise-calculator",
    path: "/health-insurance/rate-rise-calculator",
    component: () => import("/codebuild/output/src1243667753/src/packages/nuxt/pages/health-insurance/rate-rise-calculator/index.vue")
  },
  {
    name: "health-insurance-visitors-visa-ovhc-details",
    path: "/health-insurance/visitors-visa-ovhc/details",
    component: () => import("/codebuild/output/src1243667753/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/details.vue")
  },
  {
    name: "health-insurance-application-payment-expired",
    path: "/health-insurance/application/payment/expired",
    component: () => import("/codebuild/output/src1243667753/src/packages/nuxt/pages/health-insurance/application/payment/expired.vue")
  },
  {
    name: "life-insurance-income-protection-quote",
    path: "/life-insurance/income-protection/quote",
    component: () => import("/codebuild/output/src1243667753/src/packages/nuxt/pages/life-insurance/income-protection/quote/index.vue")
  },
  {
    name: "health-insurance-visitors-visa-ovhc-thank-you",
    path: "/health-insurance/visitors-visa-ovhc/thank-you",
    component: () => import("/codebuild/output/src1243667753/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/thank-you.vue")
  },
  {
    name: "life-insurance-income-protection-quote-details",
    path: "/life-insurance/income-protection/quote/details",
    component: () => import("/codebuild/output/src1243667753/src/packages/nuxt/pages/life-insurance/income-protection/quote/details.vue")
  },
  {
    name: "life-insurance-quote-application-pre-assesment",
    path: "/life-insurance/quote/application/pre-assesment",
    component: () => import("/codebuild/output/src1243667753/src/packages/nuxt/pages/life-insurance/quote/application/pre-assesment.vue")
  },
  {
    name: "health-insurance-visitors-visa-ovhc-confirmation",
    path: "/health-insurance/visitors-visa-ovhc/confirmation",
    component: () => import("/codebuild/output/src1243667753/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/confirmation.vue")
  },
  {
    name: "dashboard-vault-product-vertical-completion",
    path: "/dashboard/vault/:product([^/]*)*/:vertical()/completion",
    meta: completionrggE_dsKzKDBnF9Kc1jmHLZEoBj7wQE2CmcwOEIqP9UMeta || {},
    component: () => import("/codebuild/output/src1243667753/src/packages/nuxt/pages/dashboard/vault/[...product]/[vertical]/completion.vue")
  },
  {
    name: "health-insurance-visitors-visa-ovhc-quotes-results",
    path: "/health-insurance/visitors-visa-ovhc/quotes-results",
    component: () => import("/codebuild/output/src1243667753/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/quotes-results.vue")
  },
  {
    name: "life-insurance-income-protection-quote-confirmation",
    path: "/life-insurance/income-protection/quote/confirmation",
    component: () => import("/codebuild/output/src1243667753/src/packages/nuxt/pages/life-insurance/income-protection/quote/confirmation.vue")
  },
  {
    name: "dashboard-vault-product-form-energy-step-step",
    path: "/dashboard/vault/:product([^/]*)*/form/energy/step/:step()",
    meta: _91step_93DctHuIr3ygIReMmBWLRY8yqgRt96Ugho8c7iK8md_UYMeta || {},
    component: () => import("/codebuild/output/src1243667753/src/packages/nuxt/pages/dashboard/vault/[...product]/form/energy/step/[step].vue")
  },
  {
    name: "dashboard-vault-product-form-generic-step-step",
    path: "/dashboard/vault/:product([^/]*)*/form/generic/step/:step()",
    meta: _91step_93O6UNR4uKJZ4bKZ_CieSg1AsIbcijxSidhMIJVxGYKvcMeta || {},
    component: () => import("/codebuild/output/src1243667753/src/packages/nuxt/pages/dashboard/vault/[...product]/form/generic/step/[step].vue")
  },
  {
    name: "health-insurance-visitors-visa-ovhc-application-step",
    path: "/health-insurance/visitors-visa-ovhc/application/:step()",
    component: () => import("/codebuild/output/src1243667753/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/application/[step].vue")
  },
  {
    name: "dashboard-vault-product-form-home-loan-step-step",
    path: "/dashboard/vault/:product([^/]*)*/form/home-loan/step/:step()",
    meta: _91step_935J3WPp_45VqdasgonMQp2pSlcDZMXZX4ESZy4joEOxmr8Meta || {},
    component: () => import("/codebuild/output/src1243667753/src/packages/nuxt/pages/dashboard/vault/[...product]/form/home-loan/step/[step].vue")
  },
  {
    name: "health-insurance-students-offer-university-form",
    path: "/health-insurance/students-offer/:university()/form",
    component: () => import("/codebuild/output/src1243667753/src/packages/nuxt/pages/health-insurance/students-offer/[university]/form/index.vue")
  },
  {
    name: "dashboard-vault-product-vertical-additional-details",
    path: "/dashboard/vault/:product([^/]*)*/:vertical()/additional-details",
    meta: additional_45detailsF6g585y1p6QTZS9x8SdSfa4ks4NiA96y5z_455m0NAVtEMeta || {},
    component: () => import("/codebuild/output/src1243667753/src/packages/nuxt/pages/dashboard/vault/[...product]/[vertical]/additional-details.vue")
  },
  {
    name: "dashboard-vault-product-form-life-insurance-step-step",
    path: "/dashboard/vault/:product([^/]*)*/form/life-insurance/step/:step()",
    meta: _91step_93Q7LZYj_N0SQP6CjfsyWBEQRP6kXc_VrE1vXqtsQNO6EMeta || {},
    component: () => import("/codebuild/output/src1243667753/src/packages/nuxt/pages/dashboard/vault/[...product]/form/life-insurance/step/[step].vue")
  },
  {
    name: "dashboard-vault-product-form-health-insurance-step-step",
    path: "/dashboard/vault/:product([^/]*)*/form/health-insurance/step/:step()",
    meta: _91step_93RFaz0p7VcQ7l8jvQ2ej4ZoIl3Q6lhLV25oo_QtGMHpkMeta || {},
    component: () => import("/codebuild/output/src1243667753/src/packages/nuxt/pages/dashboard/vault/[...product]/form/health-insurance/step/[step].vue")
  },
  {
    name: "health-insurance-students-offer-university-terms-conditions",
    path: "/health-insurance/students-offer/:university()/terms-conditions",
    component: () => import("/codebuild/output/src1243667753/src/packages/nuxt/pages/health-insurance/students-offer/[university]/terms-conditions/index.vue")
  },
  {
    name: "health-insurance-budget-hub-health-insurance-average-cost-calculator",
    path: "/health-insurance/budget-hub/health-insurance-average-cost-calculator",
    component: () => import("/codebuild/output/src1243667753/src/packages/nuxt/pages/health-insurance/budget-hub/health-insurance-average-cost-calculator/index.vue")
  },
  {
    name: "Guides",
    path: "/credit-cards/guides",
    component: () => import("/codebuild/output/src1243667753/src/packages/nuxt/pages/[vertical]/guides.vue")
  },
  {
    name: "Companies",
    path: "/credit-cards/companies",
    component: () => import("/codebuild/output/src1243667753/src/packages/nuxt/pages/[vertical]/companies/index.vue")
  }
]